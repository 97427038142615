<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button type="primary" @click="$router.go(-1)"> 返回 </el-button>
      <el-button
        type="success"
        @click="AllsaveData"
        :loading="btnLoading"
        style="margin-left: 20px"
      >
        全部提交
      </el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @cell-click="cellClick"
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="题目类型" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.student_question_bank
              ? typeList[scope.row.student_question_bank.type].name
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="题库" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.student_question_bank
              ? scope.row.student_question_bank.question_bank_type.name
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="题目" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.student_question_bank
              ? scope.row.student_question_bank.title
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="答案" min-width="110" align="center">
        <template slot-scope="scope">
          <span
            v-for="item in scope.row.student_question_bank.answer
              ? scope.row.student_question_bank.answer.split(',')
              : []"
            :key="item"
          >
            {{ item >= 65 ? String.fromCharCode(item) : item }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="学生回答" min-width="110" align="center">
        <template slot-scope="scope">
          <!-- {{
            scope.row.student_answer_content
              ? scope.row.student_answer_content
              : "-"
          }} -->

          <span v-for="item in scope.row.student_answer_content" :key="item">
            {{ item >= 65 ? String.fromCharCode(item) : item }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="参考答案" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.student_question_bank
              ? scope.row.student_question_bank.analysis
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="题目分数" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.score }}
        </template>
      </el-table-column>
      <el-table-column label="评分" min-width="110" align="center">
        <template slot-scope="scope">
          <el-input
            ref="gain"
            v-if="scope.row.isEdit_score"
            @blur="blurClick(scope, scope.$index)"
            :autofocus="true"
            type="number"
            v-model="scope.row.answer_score"
          ></el-input>
          <el-tag v-else type="warning">
            {{ scope.row.answer_score }}
            <i class="el-icon-edit"></i>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="是否评分" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_score == 1" type="success">已评分</el-tag>
          <el-tag v-else type="danger">未评分</el-tag>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div> -->

    <el-dialog
      title="评分"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="answer" :rules="rules" :model="answer" label-width="130px">
        <el-form-item label="题目分数:" required>
          {{ score }}
        </el-form-item>
        <el-form-item label="评分:" prop="answer_score">
          <el-input type="text" v-model="answer.answer_score" clearable />
        </el-form-item>
        <!-- <el-form-item label="题目排序:" prop="account">
          <el-input type="text" v-model="form.account" clearable />
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        school_name: "",
        course_name: "",
        is_on: "",
      },
      typeList: [
        {},
        { name: "单选题" },
        { name: "多选题" },
        { name: "填空题" },
        { name: "简答题" },
        { name: "判断题" },
      ],
      dialogFormVisible: false,
      dialogStatus: "",
      form: {
        id: "",
        answer_scores: [
          // {
          //   index: "",
          //   answer_score: "",
          // },
        ],
      },
      rules: {
        answer_score: [
          { required: true, message: "评分分数不能为空", trigger: "change" },
        ],
      },
      answer: {
        answer_score: "",
      },
      btnLoading: false,
      school_list: [],
      score: "",
      index: "",
      id: null,
      type: null,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.list = JSON.parse(this.$route.query.data).snap;
    this.list.forEach((item) => {
      console.log(item);
      item.isEdit_score = false;
      if (item.student_answer_content) {
        console.log("=========111");
        item.student_answer_content = item.student_answer_content + "";
        item.student_answer_content = item.student_answer_content.split(",");
      }
    });
    console.log(JSON.parse(this.$route.query.data), "======");
  },
  methods: {
    blurClick(scope, index) {
      console.log(scope.row);
      if (scope.row.answer_score - 0 > scope.row.score - 0) {
        this.$message.error("评分分数不能大于题目分数");
        return;
      }
      scope.row.is_score = 1;
      // this.list.forEach((item) => {
      //   item.isEdit_score = false;
      // });
      this.$forceUpdate();
    },
    cellClick(row, column, event) {
      console.log(row, column, event);
      this.list.forEach((item) => {
        item.isEdit_score = false;
      });
      if (column.label === "评分") {
        row.isEdit_score = true;
        this.$forceUpdate();
        // this.$set(row, "isEdit_score", true);
      }
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleCreate(row, index) {
      // this.form.id = this.data.id;
      this.answer.answer_score = "";
      this.index = index;
      this.score = row.score;
      // this.form.answer_scores[0].index = index;
      this.dialogFormVisible = true;
      //   this.$router.push(`/E-sportsManagement/curriculumList_edit?id=${id}`);
    },
    AllsaveData() {
      this.form.id = this.id;
      this.list.forEach((item, index) => {
        this.form.answer_scores.push({
          index: index,
          answer_score: item.answer_score,
        });
      });
      this.btnLoading = true;
      request({
        url: "/api/teacherend/teacherPaper/studentAnswerLogStore",
        method: "post",
        data: this.form,
      })
        .then((response) => {
          this.btnLoading = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          // this.getList();
          this.$router.go(-1); //返回上一层
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    saveData() {
      // this.btnLoading = true;
      if (this.answer.answer_score - 0 > this.score - 0) {
        this.$message.error("评分分数不能大于题目分数");
        return;
      }
      this.form.answer_scores.push({
        index: this.index,
        answer_score: this.answer.answer_score,
      });
      this.list[this.index].answer_score = this.answer.answer_score;
      this.list[this.index].is_score = 1;
      this.dialogFormVisible = false;
      this.$forceUpdate(); //强制更新
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-icon-edit {
  margin-left: 5px;
}
</style>
